<template>
  <Navbar />
  <main class="error-page-container">
    <ErrorNotFound class="error-icon" />
    <Text
      type="p1"
      weight="bold"
      class="error-message"
    >
      {{ errorMessage }}
    </Text>

    <Button @click="handleError"> Kembali ke Halaman Awal </Button>
  </main>
  <Footer />
</template>

<script setup lang="ts">
  import Footer from '~/components/common/Footer.vue'
  import Navbar from '~/components/common/Navbar.vue'

  import ErrorNotFound from '~/assets/icons/error-not-found.svg'

  // @see: https://nuxt.com/docs/getting-started/error-handling for more key
  const props = defineProps<{ error: { statusCode: number } }>()

  const errorMessage = computed(() => {
    if (props.error.statusCode === 404) {
      return 'Halaman Tidak Ditemukan'
    }

    return 'Terjadi Kesalahan'
  })

  const handleError = () => clearError({ redirect: '/' })
</script>

<style scoped lang="scss">
  .error-page-container {
    background: $base300;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .error-icon {
      width: 200px;
      height: 200px;
    }

    .error-message {
      margin-bottom: 48px;
    }
  }
</style>
